export default function divisionReducer(state = { local: [], database: [], status: {} }, action) {
  switch (action.type) {
    case 'LOAD_DIVISIONS_SUCCESS':
      return standardReturn(action.database);
    case 'UPDATE_DIVISIONS_SUCCESS':
      return standardReturn(action.database);
    default:
      return state;
  }
}

/* Create a local copy for editing and sort it */
function standardReturn(database) {
  database = database.sort(sort);
  const local = [];
  for (const entry of database) { local.push(Object.assign({}, entry)); }
  return { local, database, status: {} };
}

function sort(a, b) {
  if (a.ext_division_id < b.ext_division_id) { return -1; }
  if (a.ext_division_id > b.ext_division_id) { return 1; }
  return 0;
}
