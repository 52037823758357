import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setIdToken, setAccessToken, getLoginPath } from '../utils/AuthService';

const getParameterByName = (string, name) => {
  const match = RegExp('[#&]' + name + '=([^&]*)').exec(string);
  if (!match) { return ''; }
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  useEffect(() => {
    const error = getParameterByName(location.hash, 'error');
    if (error) {
      console.error(error);
      setMessage(getParameterByName(location.hash, 'error_description'));
      return;
    }
    setAccessToken();
    setIdToken();
    navigate(getLoginPath());
  }, [location, navigate]);

  const onContinue = () => {
    navigate(getLoginPath());
  };

  if (message) {
    return (
      <div className="container center middle">
        <div>{message}</div>
        <button
          type="button"
          className="btn btn-primary refresh-button"
          onClick={onContinue}>Continue</button>
      </div>
    );
  }
  return null;
};

export default Callback;
