import React from 'react';
import { Link } from 'react-router-dom';
import NavTop from '../../components/NavTop';
import InputText from '../../components/InputText';
import FooterButtons from '../../components/FooterButtons';
import TeamSelector from '../team/Selector';
import DivisionSelector from '../division/Selector';

const EditTable = ({ local, onChange, onSave, onDelete, onRefresh, disable, hasChanged, currentYear }) => {
  return (
    <div className="container">
      <NavTop currentYear={currentYear} />
      <h1>Teams</h1>
      <div className="col-sm-12">
        <form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="medium">Edit</th>
                <th className="xlarge">Team</th>
                <th className="xlarge">Division</th>
                <th className="medium">City</th>
                <th className="medium">Abbrev</th>
                <th className="medium">Delete</th>
              </tr>
            </thead>
            <tbody>
              {local.map((entry) =>
                <tr key={entry.id}>
                  <td className="medium">
                    {entry.team ? <Link to={'/seasons/' + currentYear + '/teams/' + entry.team.ext_team_id}><button type="button" className="btn btn-info">Games/Roster</button></Link> : ''}
                  </td>
                  <td className="xlarge">
                    <TeamSelector
                      id={entry.id}
                      value={Number(entry.team_id)}
                      deleted={entry.deleted}
                      disabled={disable}
                      onChange={onChange}
                    />
                  </td>
                  <td className="xlarge">
                    <DivisionSelector
                      id={entry.id}
                      value={Number(entry.division_id)}
                      deleted={entry.deleted}
                      disabled={disable}
                      onChange={onChange}
                    />
                  </td>
                  <td className="medium">
                    <InputText
                      type="text"
                      name="city"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    <InputText
                      type="text"
                      name="abbrev"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    <button
                      type="button"
                      className="btn btn-danger"
                      name="delete"
                      disabled={disable || entry.empty || entry.cannot_delete}
                      onClick={() => onDelete(entry.id)}>
                      {entry.deleted ? 'Undo' : 'Delete'}
                    </button>
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
          <FooterButtons
            hasChanged={hasChanged}
            disabled={disable}
            onSave={onSave}
            onRefresh={onRefresh}
          />
        </form>
      </div>
    </div>
  );
};

export default EditTable;
