import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Callback from './components/Callback';
import CognitoUsersPage from './models/cognitoUsers/EditPage';
import DivisionEditPage from './models/division/EditPage';
import GameEditPage from './models/game/EditPage';
import LocationEditPage from './models/location/EditPage';
import PlayerEditPage from './models/player/EditPage';
import RosterEditPage from './models/roster/EditPage';
import SeasonsEditPage from './models/season/EditPage';
import TeamEditPage from './models/team/EditPage';
import TeamSeasonsEditPage from './models/teamSeason/EditPage';
import TeamSeasonGameEditPage from './models/teamSeasonGame/EditPage';
import TsgAuditRecentEditPage from './models/tsgAuditRecent/EditPage';
import { ToastContainer } from 'react-toastify';

const routes = () => {
  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} autoClose={2000} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SeasonsEditPage />} />
          <Route path="/seasons" element={<SeasonsEditPage />} />
          <Route path="/teams" element={<TeamEditPage />} />
          <Route path="/divisions" element={<DivisionEditPage />} />
          <Route path="/players" element={<PlayerEditPage />} />
          <Route path="/locations" element={<LocationEditPage />} />
          <Route path="/recent-stats" element={<TsgAuditRecentEditPage />} />
          <Route path="/users" element={<CognitoUsersPage />} />
          <Route path="/seasons/:year" element={<TeamSeasonsEditPage />} />
          <Route path="/seasons/:year/teams" element={<TeamSeasonsEditPage />} />
          <Route path="/seasons/:year/teams/:team" element={<GameEditPage />} />
          <Route path="/seasons/:year/teams/:team/games" element={<GameEditPage />} />
          <Route path="/seasons/:year/teams/:team/game-stats" element={<TeamSeasonGameEditPage />} />
          <Route path="/seasons/:year/teams/:team/roster" element={<RosterEditPage />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default routes;
