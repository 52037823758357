export default function statusReducer(state = [], action) {
  switch (action.type) {
    case 'LOAD_STATUSES_SUCCESS':
      return standardReturn(action.database);
    default:
      return state;
  }
}

/* Create a local copy for editing and sort it */
function standardReturn(database) {
  return database.sort(sort);
}

function sort(a, b) {
  if (a.id < b.id) { return -1; }
  if (a.id > b.id) { return 1; }
  return 0;
}
