import React from 'react';
import { Link } from 'react-router-dom';
import NavTop from '../../components/NavTop';
import NavRoot from '../../components/NavRoot';
import FooterButtons from '../../components/FooterButtons';
import InputCheckbox from '../../components/InputCheckbox';
import InputText from '../../components/InputText';
import EditTableCellGameImport from './EditTableCellGameImport';

const EditTable = ({ local, onChange, onSave, onDelete, onRefresh, disable, hasChanged }) => {
  return (
    <div className="container">
      <NavTop />
      <NavRoot page="seasons" />
      <div className="col-sm-8">
        <form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="small">Edit</th>
                <th className="small">Year</th>
                <th className="small center">Current</th>
                <th className="large">Reg Start</th>
                <th className="large">Reg End</th>
                <th className="large">Playoffs Start</th>
                <th className="large">Playoffs End</th>
                <th className="small center">Locked</th>
                <th className="large">Import games</th>
                <th className="medium">Delete</th>
              </tr>
            </thead>
            <tbody>
              {local.map((entry) =>
                <tr key={entry.id}>
                  <td className="small">
                    {!entry.empty && !entry.insert ? <Link to={'/seasons/' + entry.year}><button type="button" className="btn btn-info">Teams</button></Link> : ''}
                  </td>
                  <td className="small">
                    <InputText
                      type="number"
                      name="year"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="small center"><InputCheckbox
                    name="current"
                    disabled={disable || entry.current}
                    entry={entry}
                    handleChange={(e) => onChange({ event: e, id: entry.id, uncheckOthers: true })} />
                  </td>
                  <td className="large">
                    <InputText
                      type="date"
                      name="regSeasonStartDate"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="large">
                    <InputText
                      type="date"
                      name="regSeasonEndDate"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="large">
                    <InputText
                      type="date"
                      name="playoffsStartDate"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="large">
                    <InputText
                      type="date"
                      name="playoffsEndDate"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="small center"><InputCheckbox
                    name="lock"
                    disabled={disable}
                    entry={entry}
                    handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <EditTableCellGameImport
                    season={entry}
                    disabled={disable || entry.deleted || entry.insert}
                  />
                  <td className="medium">
                    <button
                      type="button"
                      className="btn btn-danger"
                      name="delete"
                      disabled={disable || entry.empty || entry.cannot_delete}
                      onClick={() => onDelete(entry.id)}>
                      {entry.deleted ? 'Undo' : 'Delete'}
                    </button>
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
          <FooterButtons
            hasChanged={hasChanged}
            disabled={disable}
            onSave={onSave}
            onRefresh={onRefresh}
          />
        </form>
      </div>
    </div>
  );
};

export default EditTable;
