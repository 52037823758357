import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './Actions';
import EditTable from './EditTable';
import PageTemplateTable from '../../components/PageTemplateTable';

class EditPage extends PageTemplateTable {
  loadData({ page }) {
    this.props.actions.load(page);
    return;
  }

  render() {
    return (
      <EditTable
        currentPage={Number(this.props.page)}
        local={this.state.local}
        onChange={this.onChangeInput}
        onRefresh={this.onRefresh} />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { reducerState: state.tsgAuditRecentState[ownProps.page] };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

const Connect = connect(mapStateToProps, mapDispatchToProps)(EditPage);

export default () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  return <Connect page={pathParts[2]} />;
};
