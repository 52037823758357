import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './Actions';
import EditTable from './EditTable';
import PageTemplateTable from '../../components/PageTemplateTable';

const MAX_NUMBER_OF_ROWS = 30;

class EditPage extends PageTemplateTable {
  constructor(props, context) {
    super(props, context);

    this.onSearchKeyUp = this.onSearchKeyUp.bind(this);
    this.addPlayer = this.addPlayer.bind(this);
  }
  loadData() {
    this.props.actions.load();
    return;
  }

  addBlankEntry(array) { }

  saveChanges(event) {
    event.preventDefault();
    this.setState({ status: { ...this.state.status, editing: true } });
    this.props.actions.update(this.state.local);
  }

  addPlayer(event) {
    event.preventDefault();
    this.setState({ status: { ...this.state.status, editing: true } });
    const firstName = event.target.form[0].value;
    const lastName = event.target.form[1].value;
    event.target.form[0].value = '';
    event.target.form[1].value = '';
    this.props.actions.add(firstName, lastName);
  }

  onSearchKeyUp(event) {
    const entries = this.state.local;
    const values = event.target.value.toLowerCase().split(' ');
    for (const entry of entries) {
      let hidden = false;
      for (const value of values) {
        let hasValue = false;
        if (entry.first_name && entry.first_name.toLowerCase().substring(0, value.length) === value) {
          hasValue = true;
        } else if (entry.last_name && entry.last_name.toLowerCase().substring(0, value.length) === value) {
          hasValue = true;
        } else if (entry.ext_player_id && entry.ext_player_id.toLowerCase().substring(0, value.length) === value) { hasValue = true; }
        if (!hasValue) { hidden = true; }
      }
      entry.hidden = hidden;
    }
    return this.setState({ local: entries });
  }

  render() {
    return (
      <EditTable
        local={(this.state.local).filter((entry) => !entry.hidden).filter((entry, index) => index < MAX_NUMBER_OF_ROWS)}
        onChange={this.onChangeInput}
        onSave={this.saveChanges}
        onDelete={this.onDeleteEntry}
        onRefresh={this.onRefresh}
        onSearchKeyUp={this.onSearchKeyUp}
        onAdd={this.addPlayer}
        hasChanged={this.state.status.hasChanged}
        disable={this.state.status.editing} />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { reducerState: state.playerState };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);
