import React from 'react';
import PropTypes from 'prop-types'; // ES6
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './Actions';
import NavDropdown from './NavDropdown';

class Nav extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      database: this.props.reducerState ? this.props.reducerState.database : [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.reducerState) {
      nextProps.actions.load(nextProps.currentYear);
    }
    this.setState({ database: nextProps.reducerState ? nextProps.reducerState.database : [] });
  }

  componentDidMount() {
    if (this.state.database.length === 0) {
      this.props.actions.load(this.props.currentYear);
    }
  }

  render() {
    return (
      <NavDropdown
        database={this.state.database}
        currentYear={this.props.currentYear}
        currentTeam={this.props.currentTeam}
        currentTeamPage={this.props.currentTeamPage} />
    );
  }
}

Nav.propTypes = {
  reducerState: PropTypes.shape({ database: PropTypes.array.isRequired }),
  actions: PropTypes.object.isRequired,
  currentYear: PropTypes.number.isRequired,
  currentTeam: PropTypes.string,
  currentTeamPage: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return { reducerState: state.teamSeasonState[ownProps.currentYear] };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
