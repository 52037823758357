import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { login, logout, isLoggedIn } from '../utils/AuthService';
import SeasonsNav from '../models/season/Nav';
import TeamSeasonsNav from '../models/teamSeason/Nav';

const NavTop = ({ currentYear, currentTeam, currentTeamPage }) => {
  const navigate = useNavigate();
  return (
    <nav className="navbar navbar-default">
      <div className="navbar-header">
        <Link className="navbar-brand" to="/">UFA Database Editor</Link>
      </div>
      <ul className="nav navbar-nav">
        <li>
          <SeasonsNav currentYear={currentYear} currentTeam={currentTeam} currentTeamPage={currentTeamPage} />
        </li>
        {currentYear ? <li><TeamSeasonsNav currentYear={currentYear} currentTeam={currentTeam} currentTeamPage={currentTeamPage} /></li> : ''}
      </ul>
      <ul className="nav navbar-nav navbar-right">
        <li>
          {
            (isLoggedIn()) ? (<button className="btn btn-danger log" onClick={() => logout(navigate)}>Log out </button>) : (<button className="btn btn-info log" onClick={() => login()}>Log In</button>)
          }
        </li>
      </ul>
    </nav>
  );
};

export default NavTop;
