import React from 'react';
import PropTypes from 'prop-types'; // ES6

class InputSelect extends React.Component {
  render() {
    const currentNameIndex = this.props.database.findIndex((entry) => entry.id === this.props.value);
    if (currentNameIndex !== -1) { this.currentName = this.props.database[currentNameIndex][this.props.field]; }
    if (this.props.nullValue && this.props.value === 0) { this.currentName = this.props.nullValue; }

    if (this.props.deleted) {
      return (
        <s className="left-margin" name={this.props.name}>
          {this.currentName}
        </s>
      );
    }
    if (this.props.disabled) {
      return (
        <span className="left-margin" name={this.props.name}>
          {this.currentName}
        </span>
      );
    }
    return (
      <select
        name={this.props.name}
        value={this.props.value || 0}
        onChange={(e) => this.props.onChange({ event: e, id: this.props.id })}>
        {this.props.nullValue ? <option value="0">{this.props.nullValue}</option> : ''}
        {this.props.database.map((entry, arrayIndex) =>
          <option
            key={entry.id}
            value={entry.id}>
            {entry[this.props.field]}
          </option>,
        )}
      </select>
    );
  }
}

InputSelect.propTypes = {
  database: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  deleted: PropTypes.bool,
  disabled: PropTypes.bool,
  nullValue: PropTypes.string,
};

export default InputSelect;
