import { combineReducers } from 'redux';
import cognitoUsersReducer from '../models/cognitoUsers/Reducer';
import divisionReducer from '../models/division/Reducer';
import gameReducer from '../models/game/Reducer';
import locationReducer from '../models/location/Reducer';
import playerReducer from '../models/player/Reducer';
import rosterReducer from '../models/roster/Reducer';
import seasonReducer from '../models/season/Reducer';
import statusReducer from '../models/status/Reducer';
import teamReducer from '../models/team/Reducer';
import teamSeasonReducer from '../models/teamSeason/Reducer';
import teamSeasonGameReducer from '../models/teamSeasonGame/Reducer';
import tsgAuditRecentReducer from '../models/tsgAuditRecent/Reducer';

const rootReducer = combineReducers({
  cognitoUsersState: cognitoUsersReducer,
  divisionState: divisionReducer,
  gameState: gameReducer,
  locationState: locationReducer,
  playerState: playerReducer,
  rosterState: rosterReducer,
  seasonState: seasonReducer,
  statusState: statusReducer,
  teamState: teamReducer,
  teamSeasonState: teamSeasonReducer,
  teamSeasonGameState: teamSeasonGameReducer,
  tsgAuditRecentState: tsgAuditRecentReducer,
});

export default rootReducer;
