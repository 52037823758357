// src/reducers/seasonReducer.js

export default function seasonReducer(state = { local: [], database: [], status: {} }, action) {
  switch (action.type) {
    case 'LOAD_SEASONS_SUCCESS':
      return standardReturn(action.database);
    case 'UPDATE_SEASONS_SUCCESS':
      return standardReturn(action.database);
    default:
      return state;
  }
}

/* Create a local copy for editing and sort it */
function standardReturn(database) {
  database = database.sort(sort);
  const local = [];
  for (const entry of database) { local.push(Object.assign({}, entry)); }
  return { local, database, status: {} };
}

function sort(a, b) {
  return b.year - a.year;
}
