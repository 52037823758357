// src/reducers/seasonReducer.js

export default function teamSeasonReducer(state = { null: { local: [], database: [], status: {} } }, action) {
  switch (action.type) {
    case 'LOAD_TEAM_SEASONS_SUCCESS':
      return standardReturn(state, action.year, action.database);
    case 'UPDATE_TEAM_SEASONS_SUCCESS':
      return standardReturn(state, action.year, action.database);
    default:
      return state;
  }
}

/* Create a local copy for editing and sort it */
function standardReturn(state, year, database) {
  database = database.sort(sort);
  const local = [];
  for (const entry of database) { local.push(Object.assign({}, entry)); }
  state[year] = { local, database, status: {} };
  return Object.assign({}, state);
}

function sort(a, b) {
  if (a.team.ext_team_id < b.team.ext_team_id) { return -1; }
  if (a.team.ext_team_id > b.team.ext_team_id) { return 1; }
  return 0;
}
