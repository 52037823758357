export default function rosterReducer(state = { null: { local: [], database: [], status: {} } }, action) {
  switch (action.type) {
    case 'LOAD_ROSTER_SUCCESS':
      return standardReturn(state, action.year, action.team, action.database);
    case 'UPDATE_ROSTER_SUCCESS':
      return standardReturn(state, action.year, action.team, action.database);
    case 'ADD_ROSTER_SUCCESS':
      const year = action.year;
      const team = action.team;
      const key = year + '|' + team;
      const { local, database, status } = state[key];
      const newRoster = action.newEntry;
      if (newRoster) {
        setDisplayName(newRoster);
        database.unshift(Object.assign({}, newRoster));
        local.unshift(newRoster);
      }
      const newState = Object.assign({}, state);
      newState[key] = { local, database, status };
      return newState;
    default:
      return state;
  }
}

function setDisplayName(entry) {
  entry.displayName = entry.player.first_name + ' ' + entry.player.last_name + (entry.jersey_number ? ' #' + entry.jersey_number : '');
}

/* Create a local copy for editing and sort it */
function standardReturn(state, year, team, database) {
  for (const entry of database) { setDisplayName(entry); }
  database = database.sort(sort);
  const local = [];
  for (const entry of database) { local.push(Object.assign({}, entry)); }
  state[year + '|' + team] = { local, database, status: {} };
  return Object.assign({}, state);
}

function sort(a, b) {
  if (a.active && !b.active) { return -1; }
  if (!a.active && b.active) { return 1; }
  if (Number(a.jersey_number) < Number(b.jersey_number)) { return -1; }
  if (Number(a.jersey_number) > Number(b.jersey_number)) { return 1; }
  return 0;
}
