import React from 'react';
import PropTypes from 'prop-types'; // ES6

class InputCheckbox extends React.Component {
  render() {
    const checked = this.props.entry[this.props.name] ? this.props.entry[this.props.name] : '';
    if (this.props.entry.deleted) {
      return (
        <s type="checkbox" name={this.props.name}>
          {this.props.entry[this.props.name] ? '✔' : '☐'}
        </s>
      );
    }
    if (this.props.disabled) {
      return (
        <span type="checkbox" name={this.props.name}>
          {this.props.entry[this.props.name] ? '✔' : '☐'}
        </span>
      );
    }
    return (
      <input
        type="checkbox"
        name={this.props.name}
        checked={checked}
        onChange={this.props.handleChange} />
    );
  }
}

InputCheckbox.propTypes = {
  entry: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default InputCheckbox;
