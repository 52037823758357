import React from 'react';
import NavTop from '../../components/NavTop';
import NavTeam from '../../components/NavTeam';
import FooterButtons from '../../components/FooterButtons';
import InputCheckbox from '../../components/InputCheckbox';
import InputText from '../../components/InputText';
import TeamSeasonSelector from '../teamSeason/Selector';
import StatusSelector from '../status/Selector';
import InputSelect from '../../components/InputSelect';

const GAME_TYPES = [
  { id: 1, name: 'Reg Season' },
  { id: 2, name: 'Playoffs' },
  { id: 3, name: 'Pre-Season' },
  { id: 4, name: 'All-Star' },
];

const EditTable = ({ local, onChange, onSave, onRefresh, disable, hasChanged, currentYear, currentTeam }) => {
  return (
    <div className="container">
      <NavTop currentYear={currentYear} currentTeam={currentTeam} currentTeamPage="games" />
      <NavTeam page="games" currentYear={currentYear} currentTeam={currentTeam} />
      <div className="col-sm-12">
        <form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="narrow">Away Team</th>
                <th className="narrow">Home Team</th>
                <th className="narrow">Away Score</th>
                <th className="narrow">Home Score</th>
                <th className="xlarge">Start Date</th>
                <th className="medium">Start Time</th>
                <th className="narrow">Time Zone</th>
                <th className="medium">Status</th>
                <th className="medium">Week</th>
                <th className="medium">Type</th>
                <th className="narrow">Lock</th>
                <th className="narrow">Ignore</th>
                <th className="xlarge">Streaming URL</th>
              </tr>
            </thead>
            <tbody>
              {local.map((entry, arrayIndex) =>
                <tr key={entry.id}>
                  <td className="narrow">
                    <TeamSeasonSelector
                      name="team_season_id_away"
                      id={entry.id}
                      value={Number(entry.team_season_id_away)}
                      deleted={entry.deleted}
                      disabled={(!entry.empty && !entry.insert) || disable}
                      onChange={onChange}
                      currentYear={currentYear}
                    />
                  </td>
                  <td className="narrow">
                    <TeamSeasonSelector
                      name="team_season_id_home"
                      id={entry.id}
                      value={Number(entry.team_season_id_home)}
                      deleted={entry.deleted}
                      disabled={(!entry.empty && !entry.insert) || disable}
                      onChange={onChange}
                      currentYear={currentYear}
                    />
                  </td>
                  <td className="narrow">
                    <InputText
                      type="number"
                      name="score_away"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="narrow">
                    <InputText
                      type="number"
                      name="score_home"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="xlarge">
                    <InputText
                      type="date"
                      name="start_date"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    <InputText
                      type="time"
                      name="start_time"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="narrow">
                    <InputText
                      type="text"
                      name="start_timezone"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    <StatusSelector
                      name="status_id"
                      id={entry.id}
                      value={entry.status_id}
                      deleted={entry.deleted}
                      disabled={disable}
                      onChange={onChange}
                      currentYear={currentYear}
                    />
                  </td>
                  <td className="medium">
                    <InputText
                      type="text"
                      name="aw_section"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium center">
                    <InputSelect
                      name="type_id"
                      database={GAME_TYPES}
                      field="name"
                      id={entry.id}
                      value={entry.type_id}
                      deleted={entry.deleted}
                      disabled={disable}
                      onChange={onChange} />
                  </td>
                  <td className="narrow center"><InputCheckbox
                    name="lock"
                    disabled={disable}
                    entry={entry}
                    handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="narrow center"><InputCheckbox
                    name="ignore_game"
                    disabled={disable}
                    entry={entry}
                    handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="xlarge">
                    <InputText
                      type="text"
                      name="streaming_url"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
          <FooterButtons
            hasChanged={hasChanged}
            disabled={disable}
            onSave={onSave}
            onRefresh={onRefresh}
          />
        </form>
      </div>
    </div>
  );
};

export default EditTable;
