import request from '../../utils/request';

const BASE_URL = process.env.REACT_APP_EXPRESS_URL;
const URL = BASE_URL + '/players';

class Api {
  static async getAll() {
    const response = await request('get', URL);
    return response.data;
  }

  static async update(changedEntries) {
    await request('post', URL, { changedEntries });
    return this.getAll();
  }

  static async add(firstName, lastName) {
    const response = await request('put', URL, { firstName, lastName });
    const newEntry = response.data;
    newEntry.teams = [];
    return { ...newEntry };
  }
}

export default Api;
