import Api from './Api';

let loading = false;

function load(page) {
  return async function (dispatch) {
    if (loading) { return; }
    loading = true;
    let database = [];
    database = await Api.getRecent(page);
    dispatch({ type: 'LOAD_TSG_AUDIT_RECENT_SUCCESS', database, page });
    loading = false;
    return database;
  };
}

const actions = { load };
export default actions;
