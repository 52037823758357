import Api from './Api';

let loading = false;

function load() {
  return async function (dispatch) {
    if (loading) { return; }
    loading = true;
    const database = await Api.getAll();
    dispatch({ type: 'LOAD_STATUSES_SUCCESS', database });
    loading = false;
    return database;
  };
}

const actions = { load };
export default actions;
