import React from 'react';
import PropTypes from 'prop-types'; // ES6

class InputText extends React.Component {
  render() {
    const value = this.props.entry[this.props.name] ? this.props.entry[this.props.name] : '';
    if (this.props.entry.deleted) {
      return (
        <s name={this.props.name}>{value}</s>
      );
    }
    if (this.props.disabled) {
      return (
        <span name={this.props.name}>{value}</span>
      );
    }
    return (
      <input
        type={this.props.type}
        name={this.props.name}
        placeholder={this.props.name}
        validate={this.props.validate}
        value={value}
        onChange={this.props.handleChange} />
    );
  }
}

InputText.propTypes = {
  entry: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  validate: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputText;
