import request from '../../utils/request';

const BASE_URL = process.env.REACT_APP_EXPRESS_URL;

class Api {
  static async getAll(year, team) {
    const url = BASE_URL + '/season/' + year + '/team/' + team + '/roster';
    const response = await request('get', url);
    return response.data;
  }

  static async update(year, team, changedEntries) {
    const url = BASE_URL + '/season/' + year + '/team/' + team + '/roster';
    await request('post', url, { changedEntries });
    return this.getAll(year, team);
  }

  static async createPlayer(firstName, lastName) {
    const addPlayerURL = BASE_URL + '/players';
    const response = await request('put', addPlayerURL, { firstName, lastName });
    const newPlayer = response.data;
    newPlayer.teams = [];
    return newPlayer;
  }
}

export default Api;
