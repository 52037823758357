export default function playerReducer(state = { local: [], database: [], status: {} }, action) {
  switch (action.type) {
    case 'LOAD_PLAYERS_SUCCESS':
      return standardReturn(action.database);
    case 'UPDATE_PLAYERS_SUCCESS':
      return standardReturn(action.database);
    case 'ADD_PLAYER_SUCCESS':
      const database = state.database;
      const local = state.local;
      const newPlayer = action.newEntry;
      database.unshift(newPlayer);
      local.unshift(newPlayer);
      return { local, database, status: {} };
    default:
      return state;
  }
}

/* Create a local copy for editing and sort it */
function standardReturn(database) {
  database = database.sort(sort);
  const local = [];
  for (const entry of database) { local.push(Object.assign({}, entry)); }
  return { local, database, status: {} };
}

function sort(a, b) {
  if (a.ext_player_id && !b.ext_player_id) { return 1; }
  if (!a.ext_player_id && b.ext_player_id) { return -1; }
  if (a.ext_player_id < b.ext_player_id) { return -1; }
  if (a.ext_player_id > b.ext_player_id) { return 1; }
  return 0;
}
