import decode from 'jwt-decode';
import auth0 from 'auth0-js';
import seasonActions from '../models/season/Actions';
import teamActions from '../models/team/Actions';
import divisionActions from '../models/division/Actions';
import playerActions from '../models/player/Actions';
import store from './store';

const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';
const LOGIN_PATH_NAME = 'login_path_name';

const CLIENT_ID = 'RhROt6iUO05qDxmeJ1wyMKDcnFH4Zfo4';
const CLIENT_DOMAIN = 'audl-stat-server.auth0.com';
const REDIRECT = process.env.REACT_APP_MY_URL + '/callback';
const AUDIENCE = 'audl-stats-crud';

let accessToken;
let hasInitialized = false;

var auth = new auth0.WebAuth({
  clientID: CLIENT_ID,
  domain: CLIENT_DOMAIN,
});

export function login() {
  localStorage.setItem(LOGIN_PATH_NAME, window.location.pathname);
  auth.authorize({
    responseType: 'token id_token',
    redirectUri: REDIRECT,
    audience: AUDIENCE,
  });
}

export function getLoginPath() {
  const loginPathName = localStorage.getItem(LOGIN_PATH_NAME);
  localStorage.removeItem(LOGIN_PATH_NAME);
  if (!loginPathName) { return '/'; }
  return loginPathName;
}

export function logout(navigate) {
  clearIdToken();
  clearAccessToken();
  navigate(window.location.pathname);
}

// Get and store id_token in local storage
export function setIdToken() {
  const idToken = getParameterByName('id_token');
  localStorage.setItem(ID_TOKEN_KEY, idToken);
}

// Get and store access_token in local storage
export function setAccessToken() {
  accessToken = getParameterByName('access_token');
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  if (hasInitialized) {
    hasInitialized = true;
    store.dispatch(seasonActions.load());
    store.dispatch(teamActions.load());
    store.dispatch(divisionActions.load());
    store.dispatch(playerActions.load());
  }
}

// Helper function that will allow us to extract the access_token and id_token
function getParameterByName(name) {
  const match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
  if (!match) { return ''; }
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function getIdToken() {
  return localStorage.getItem(ID_TOKEN_KEY);
}

export function getAccessToken() {
  if (!accessToken) {
    accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  }
  return accessToken;
}

function clearIdToken() {
  localStorage.removeItem(ID_TOKEN_KEY);
}

function clearAccessToken() {
  accessToken = null;
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function isLoggedIn() {
  const idToken = getIdToken();
  return !!idToken && !isTokenExpired(idToken);
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  const isExpired = (expirationDate < new Date());
  if (isExpired) {
    clearIdToken();
    clearAccessToken();
  }
  return isExpired;
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);

  const date1 = new Date(0);
  date1.setUTCSeconds(token.iat);
  if (!token.exp) { return null; }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}
