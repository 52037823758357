import React from 'react';
import NavTop from '../../components/NavTop';
import NavRoot from '../../components/NavRoot';
import FooterButtons from '../../components/FooterButtons';
import InputText from '../../components/InputText';
import FilterBar from '../../components/FilterBar';

const EditTable = ({ local, onChange, onSave, onAdd, onDelete, onRefresh, onSearchKeyUp, disable, hasChanged }) => {
  return (
    <div className="container">
      <NavTop />
      <NavRoot page="players" />
      <div className="col-sm-8">
        <FilterBar name="playerFilterBar" onSearchKeyUp={onSearchKeyUp} />
        <button type="button" disabled={disable} className="btn btn-primary add-player-btn" data-toggle="modal" data-target="#newPlayer">Add Player</button>
        <form>
          <table id="playerTable" className="table table-striped">
            <thead>
              <tr>
                <th className="medium">First Name</th>
                <th className="medium">Last Name</th>
                <th className="medium">URL String</th>
                <th className="xlarge">Team</th>
                <th className="medium">Delete</th>
              </tr>
            </thead>
            <tbody>
              {local.filter((entry) => !entry.hidden).map((entry, arrayIndex) =>
                <tr key={entry.id}>
                  <td className="medium">
                    <InputText
                      type="text"
                      name="first_name"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    <InputText
                      type="text"
                      name="last_name"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    <InputText
                      type="text"
                      name="ext_player_id"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="xlarge">
                    <span>{entry.teams.length > 0 ? entry.teams[0] : ''}</span>
                  </td>
                  <td className="medium">
                    <button
                      type="button"
                      className="btn btn-danger"
                      name="delete"
                      disabled={disable || entry.empty || entry.cannot_delete}
                      onClick={() => onDelete(entry.id)}>
                      {entry.deleted ? 'Undo' : 'Delete'}
                    </button>
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
          <FooterButtons
            hasChanged={hasChanged}
            disabled={disable}
            onSave={onSave}
            onRefresh={onRefresh}
          />
        </form>
      </div>
      <div id="newPlayer" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <form>
            <div className="modal-content">
              <div className="modal-header">
                <h2>Add a new player</h2>
              </div>
              <div className="modal-body">
                <div className="prompt-entry">
                  <span>First Name:</span>
                  <input type="text" placeholder="First Name" id="newPlayerFirstName" />
                </div>
                <div className="prompt-entry">
                  <span>Last Name:</span>
                  <input type="text" placeholder="Last Name" id="newPlayerLastName" />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onAdd}
                  data-dismiss="modal">Add Player</button>
                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTable;
