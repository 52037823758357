import React from 'react';
import PropTypes from 'prop-types'; // ES6
import { Modal, FormGroup, FormControl, ControlLabel, Alert, ProgressBar } from 'react-bootstrap';
import request from '../../utils/request';
const URL = process.env.REACT_APP_EXPRESS_URL + '/import-games';

class EditTableCellGameImport extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      sheetName: 'upload',
      saving: false,
      message: '',
    };
  }

  setSaving = (value) => {
    this.setState({ saving: value });
  };

  handleClose = () => {
    this.setState({ show: false, message: '' });
  };

  handleShow = () => {
    this.setState({ show: true, message: '' });
  };

  handleChange = (event) => {
    this.setState({ sheetName: event.target.value });
  };

  importGames = async () => {
    const { season } = this.props;
    const { sheetName } = this.state;

    if (!sheetName) {
      this.setState({ message: 'Need a sheet name before you can export' });
      return;
    }
    this.setState({ saving: true, message: '' });
    const response = await request('post', URL, { seasonId: season.id, sheetName });
    this.setState({ saving: false, message: response.data });
  };

  render() {
    const { disabled, season } = this.props;
    const { show, sheetName, message, saving } = this.state;
    if (season.year !== 2024) {
      return <td className="medium"></td>;
    }
    return (
      <td className="medium">
        <button
          type="button"
          className="btn btn-info"
          name="import-games"
          disabled={disabled || season.empty}
          onClick={this.handleShow}>
          Import games
        </button>
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Import games</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup controlId="formControlsSelect">
              <ControlLabel>Division Sheet Name</ControlLabel>
              <FormControl componentClass="select" placeholder="Division" onChange={this.handleChange} value={sheetName} >
                <option value="upload">Upload</option>
              </FormControl>
            </FormGroup>
            {saving ? <ProgressBar active now={100} /> : null}
            {message ? <Alert>{message}</Alert> : null}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-success"
              disabled={saving}
              onClick={this.importGames}>
              Import
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={this.handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </td>
    );
  }
}

EditTableCellGameImport.propTypes = {
  season: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default EditTableCellGameImport;
