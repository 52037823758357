import Api from './Api';

let loading = false;
let updating = false;

function load() {
  return async function (dispatch) {
    if (loading) { return; }
    loading = true;
    let database = [];
    database = await Api.getAll();
    dispatch({ type: 'LOAD_TEAMS_SUCCESS', database });
    loading = false;
    return database;
  };
}

function update(local) {
  return async function (dispatch) {
    if (updating) { return local; }
    updating = true;
    const changedEntries = local.filter((entry) =>
      entry.insert || entry.update || entry.deleted,
    );
    const database = await Api.update(changedEntries);
    dispatch({ type: 'UPDATE_TEAMS_SUCCESS', database });
    updating = false;
    return database;
  };
}

const actions = { load, update };
export default actions;
