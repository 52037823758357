export default function teamReducer(state = { null: { local: [], database: [], status: {} } }, action) {
  switch (action.type) {
    case 'LOAD_TEAM_SEASON_GAMES_SUCCESS':
      return standardReturn(state, action.year, action.team, action.database);
    case 'UPDATE_TEAM_SEASON_GAMES_SUCCESS':
      return standardReturn(state, action.year, action.team, action.database);
    default:
      return state;
  }
}

/* Create a local copy for editing and sort it */
function standardReturn(state, year, team, database) {
  database = database.sort(sort);
  const local = [];
  for (const entry of database) {
    try {
      entry.events = JSON.parse(entry.events);
    } finally {
      local.push(Object.assign({}, entry));
    }
  }
  state[year + '|' + team] = { local, database, status: {} };
  return Object.assign({}, state);
}

function sort(a, b) {
  if (a.gameId < b.gameId) { return -1; }
  return 0;
}
