import React from 'react';
import moment from 'moment';
import NavTop from '../../components/NavTop';
import NavRoot from '../../components/NavRoot';
import FooterButtons from '../../components/FooterButtons';
import InputCheckbox from '../../components/InputCheckbox';
import TeamSelector from '../team/Selector';

const EditTable = ({ local, onChange, onRefresh, onSave, disable, hasChanged }) => {
  return (
    <div className="container">
      <NavTop />
      <NavRoot page="users" />
      <div className="col-sm-12">
        <form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="medium">User</th>
                <th className="huge">Email</th>
                <th className="narrow">Admin</th>
                <th className="narrow">Director</th>
                <th className="xlarge">Team</th>
                <th className="narrow">Verified</th>
                <th className="medium">Status</th>
                <th className="large">Created On</th>
              </tr>
            </thead>
            <tbody>
              {local.map((entry, index) =>
                <tr key={index}>
                  <td className="medium">
                    {entry.id}
                  </td>
                  <td className="huge">
                    {entry.email}
                  </td>
                  <td className="narrow center"><InputCheckbox
                    name="admin"
                    disabled={disable}
                    entry={entry}
                    handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="narrow center"><InputCheckbox
                    name="director"
                    disabled={disable}
                    entry={entry}
                    handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="xlarge">
                    <TeamSelector
                      id={entry.id}
                      name="teamID"
                      value={Number(entry.teamID)}
                      deleted={entry.deleted}
                      disabled={(!entry.teamID && (entry.admin || entry.director)) || disable}
                      onChange={onChange}
                    />
                  </td>
                  <td className="narrow center"><InputCheckbox
                    name="emailVerified"
                    disabled={disable}
                    entry={entry}
                    handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <th className="medium">
                    {entry.status}
                  </th>
                  <th className="large">
                    {moment(entry.createTimestamp).format('lll')}
                  </th>
                </tr>,
              )}
            </tbody>
          </table>
          <FooterButtons
            hasChanged={hasChanged}
            disabled={disable}
            onSave={onSave}
            onRefresh={onRefresh}
          />
        </form>
      </div>
    </div>
  );
};

export default EditTable;
