import React from 'react';
import PropTypes from 'prop-types'; // ES6

class FilterBar extends React.Component {
  render() {
    return (
      <input name="filterBar" className="filter-bar" onKeyUp={(e) => this.props.onSearchKeyUp(e)} type="text" placeholder="Search.." />
    );
  }
}

FilterBar.propTypes = {
  name: PropTypes.string.isRequired,
  onSearchKeyUp: PropTypes.func.isRequired,
};

export default FilterBar;
