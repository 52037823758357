import axios from 'axios';
import { getAccessToken } from './AuthService';
import { toast } from 'react-toastify';

const request = async function (method, url, data) {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    });
    if (response.data && response.data.error) {
      response.error = true;
      response.errorMessage = response.data.errorMessage;
      delete response.data;
    }
    if (response.error) {
      toast.error(response.errorMessage);
      console.error(response.errorMessage);
    }
    return response;
  } catch (err) {
    return { data: [] };
  }
};

export default request;
