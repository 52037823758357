import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './Actions';
import EditTable from './EditTable';
import PageTemplateTable from '../../components/PageTemplateTable';

class EditPage extends PageTemplateTable {
  loadData({ year, team }) {
    this.props.actions.load(Number(year), team);
    return;
  }

  saveChanges(event) {
    event.preventDefault();
    this.setState({ status: { ...this.state.status, editing: true } });
    this.props.actions.update(this.state.local, Number(this.props.year), this.props.team);
  }

  render() {
    return (
      <EditTable
        currentYear={Number(this.props.year)}
        currentTeam={this.props.team}
        local={this.state.local}
        onChange={this.onChangeInput}
        onSave={this.saveChanges}
        onDelete={this.onDeleteEntry}
        onRefresh={this.onRefresh}
        hasChanged={this.state.status.hasChanged}
        disable={this.state.status.editing} />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { reducerState: state.teamSeasonGameState[ownProps.year + '|' + ownProps.team] };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

const Connect = connect(mapStateToProps, mapDispatchToProps)(EditPage);

export default () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  return <Connect year={pathParts[2]} team={pathParts[4]} />;
};
