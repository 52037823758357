import { Link } from 'react-router-dom';

const NavRoot = ({ page }) => {
  return (
    <ul className="nav nav-tabs">
      <li className={page === 'seasons' ? 'active' : ''}><Link to="/seasons">Seasons</Link></li>
      <li className={page === 'teams' ? 'active' : ''}><Link to="/teams">Teams</Link></li>
      <li className={page === 'divisions' ? 'active' : ''}><Link to="/divisions">Divisions</Link></li>
      <li className={page === 'players' ? 'active' : ''}><Link to="/players">Players</Link></li>
      <li className={page === 'locations' ? 'active' : ''}><Link to="/locations">Locations</Link></li>
      <li className={page === 'recent-stats' ? 'active' : ''}><Link to="/recent-stats">Recent Stats</Link></li>
      <li className={page === 'users' ? 'active' : ''}><Link to="/users">Users</Link></li>
    </ul>
  );
};

export default NavRoot;
