import React from 'react';
import PropTypes from 'prop-types'; // ES6
import { Link } from 'react-router-dom';

class NavDropdown extends React.Component {
  render() {
    return (
      <div className="dropdown">
        <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
          {this.props.currentYear ? this.props.currentYear : 'Seasons'}
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu">
          {this.props.database.map((entry, arrayIndex) =>
            <li className={this.props.currentYear === entry.year ? 'active' : ''} key={entry.id}>
              <Link to={'/seasons/' + entry.year + '/teams' + (this.props.currentTeam ? '/' + this.props.currentTeam + ((this.props.currentTeamPage) ? '/' + this.props.currentTeamPage : '') : '')}>{entry.year}</Link>
            </li>,
          )}
          <li><Link to="/seasons">Edit Seasons</Link></li>
          <li><Link to="/teams">Edit Teams</Link></li>
          <li><Link to="/divisions">Edit Divisions</Link></li>
          <li><Link to="/players">Edit Players</Link></li>
        </ul>
      </div>
    );
  }
}

NavDropdown.propTypes = {
  database: PropTypes.array.isRequired,
  currentYear: PropTypes.number,
  currentTeam: PropTypes.string,
  currentTeamPage: PropTypes.string,
};

export default NavDropdown;
