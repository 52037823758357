import React from 'react';
import NavTop from '../../components/NavTop';
import NavRoot from '../../components/NavRoot';
import FooterButtons from '../../components/FooterButtons';
import InputCheckbox from '../../components/InputCheckbox';
import InputText from '../../components/InputText';

const EditTable = ({ local, onChange, onSave, onDelete, onRefresh, disable, hasChanged }) => {
  return (
    <div className="container">
      <NavTop />
      <NavRoot page="teams" />
      <div className="col-sm-8">
        <form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="medium">Name</th>
                <th className="medium">URL String</th>
                <th className="large">Primary Color</th>
                <th className="large">Secondary Color</th>
                <th className="medium">Active</th>
                <th className="medium">Delete</th>
              </tr>
            </thead>
            <tbody>
              {local.map((entry, arrayIndex) =>
                <tr key={entry.id}>
                  <td className="medium">
                    <InputText
                      type="text"
                      name="name"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    <InputText
                      type="text"
                      name="ext_team_id"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="large">
                    <InputText
                      type="text"
                      name="primary_hex"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="large">
                    <InputText
                      type="text"
                      name="secondary_hex"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="narrow center"><InputCheckbox
                    name="active"
                    disabled={disable}
                    entry={entry}
                    handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    <button
                      type="button"
                      className="btn btn-danger"
                      name="delete"
                      disabled={disable || entry.empty || entry.cannot_delete}
                      onClick={() => onDelete(entry.id)}>
                      {entry.deleted ? 'Undo' : 'Delete'}
                    </button>
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
          <FooterButtons
            hasChanged={hasChanged}
            disabled={disable}
            onSave={onSave}
            onRefresh={onRefresh}
          />
        </form>
      </div>
    </div>
  );
};

export default EditTable;
