import { Link } from 'react-router-dom';

const NavTeam = ({ page, currentYear, currentTeam }) => {
  const baseURL = '/seasons/' + currentYear + '/teams/' + currentTeam;
  return (
    <ul className="nav nav-tabs">
      <li className={page === 'games' ? 'active' : ''}><Link to={baseURL + '/games'}>Games</Link></li>
      <li className={page === 'roster' ? 'active' : ''}><Link to={baseURL + '/roster'}>Roster</Link></li>
      <li className={page === 'game-stats' ? 'active' : ''}><Link to={baseURL + '/game-stats'}>Stats</Link></li>
    </ul>
  );
};

export default NavTeam;
