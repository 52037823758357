import React from 'react';
import PropTypes from 'prop-types'; // ES6
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './Actions';
import InputSelect from '../../components/InputSelect';

class Selector extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      database: this.props.reducerState.database,
    };

    this.hasRequestedInitialLoad = false;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ database: nextProps.reducerState.database });
  }

  componentDidMount() {
    if (this.state.database.length === 0 && !this.hasRequestedInitialLoad) {
      this.props.actions.load();
      this.hasRequestedInitialLoad = true;
    }
  }

  render() {
    return (
      <InputSelect
        field="name"
        name={this.props.name || 'team_id'}
        database={this.state.database}
        id={this.props.id}
        value={this.props.value}
        deleted={this.props.deleted}
        disabled={this.props.disabled}
        onChange={this.props.onChange}
        nullValue="Pick one" />
    );
  }
}

Selector.propTypes = {
  reducerState: PropTypes.shape({ database: PropTypes.array.isRequired }),
  actions: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  deleted: PropTypes.bool,
  disabled: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return { reducerState: state.teamState };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Selector);
