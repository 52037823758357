export default function cognitoUsersReducer(state = { local: [], database: [], status: {} }, action) {
  switch (action.type) {
    case 'LOAD_COGNITO_USERS_SUCCESS':
      return standardReturn(action.database);
    case 'UPDATE_COGNITO_USERS_SUCCESS':
      return standardReturn(action.database);
    default:
      return state;
  }
}

/* Create a local copy for editing and sort it */
function standardReturn(database) {
  database = database.sort(sort);
  const local = [];
  for (const entry of database) { local.push(Object.assign({}, entry)); }
  return { local, database, status: {} };
}

function sort(a, b) {
  if (a.createTimestamp < b.createTimestamp) { return 1; }
  if (a.createTimestamp > b.createTimestamp) { return -1; }
  return 0;
}
