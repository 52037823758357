import React from 'react';
import PropTypes from 'prop-types'; // ES6

class PageTemplateTable extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (!this.props.reducerState) {
      this.state = { local: [], database: [], status: {} };
    } else {
      this.addBlankEntry(this.props.reducerState.local);
      this.state = {
        local: this.props.reducerState.local,
        database: this.props.reducerState.database,
        status: this.props.reducerState.status,
      };
    }
    this.changeValue = this.changeValue.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onDeleteEntry = this.onDeleteEntry.bind(this);
    this.checkChanged = this.checkChanged.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.saveChanges = this.saveChanges.bind(this);

    this.hasRequestedInitialLoad = false;
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.reducerState) {
      this.loadData({ year: nextProps.year, team: nextProps.team });
      return this.setState({ local: [], database: [], status: {} });
    }
    this.addBlankEntry(nextProps.reducerState.local);
    this.setState({
      local: nextProps.reducerState.local,
      status: nextProps.reducerState.status,
      database: nextProps.reducerState.database,
    });
  }

  componentDidMount() {
    if (this.state.database.length === 0) {
      this.loadData({ year: this.props.year, team: this.props.team });
    }
  }

  onRefresh() {
    if (this.state.status.hasChanged && !window.confirm('You will lose any changed you have made. Are you sure you want to refresh?')) {
      return;
    }
    this.loadData({ year: this.props.year, team: this.props.team });
  }

  loadData({ year, team }) { }
  addBlankEntry(array) { }
  saveChanges(event) { }

  onChangeInput({ event, id, uncheckOthers }) {
    const field = event.target.name;
    const type = event.target.type;
    const local = this.state.local;
    switch (type) {
      case 'checkbox':
        this.changeValue(local, id, field, type);
        if (uncheckOthers) {
          const checkedEntries = local.filter((localEntry) => localEntry[field] === true && localEntry.id !== id);
          for (const entry of checkedEntries) { this.changeValue(local, entry.id, field, type, false); }
        }
        break;
      case 'select-one':
      case 'number':
        this.changeValue(local, id, field, type, Number(event.target.value));
        break;
      case 'text':
      case 'date':
      case 'time':
        this.changeValue(local, id, field, type, event.target.value);
        break;
      default:
        break;
    }
    return this.setLocal(local);
  }

  changeValue(local, id, field, type, value) {
    const arrayIndex = local.findIndex((entry) => entry.id === id);
    const localEntry = local[arrayIndex];
    if (type === 'checkbox' && !value) {
      localEntry[field] = !localEntry[field];
    } else {
      localEntry[field] = value;
    }

    if (localEntry.empty) {
      localEntry.empty = false;
      localEntry.insert = true;
      this.addBlankEntry(local);
      return;
    }
    if (localEntry.insert) { return; }

    const databaseEntries = this.state.database.filter((entry) => entry.id === id);
    if (!databaseEntries) { return; }     // Quit if it was newly created

    if (databaseEntries[0][field] !== localEntry[field]) {
      if (!localEntry.update) {
        localEntry.update = {};
      }
      localEntry.update[field] = true;
    } else if (localEntry.update[field]) {
      delete localEntry.update[field];
      if (!Object.keys(localEntry.update).length) {
        delete localEntry.update;
      }
    }
    return;
  }

  onDeleteEntry(id) {
    const local = this.state.local;
    const arrayIndex = local.findIndex((entry) => entry.id === id);
    if (arrayIndex === -1) {
      console.error('Tried to delete non-existent entry');
      return;
    }
    const localEntry = local[arrayIndex];
    if (localEntry.insert) {     // If newly created, just remove the row
      local.splice(arrayIndex, 1);
    } else { localEntry.deleted = !localEntry.deleted; }
    return this.setLocal(local);
  }

  setLocal(local) {
    this.checkChanged(local);
    return this.setState({ local });
  }

  checkChanged(local) {
    const status = this.state.status;
    status.hasChanged = local.findIndex((entry) => entry.insert || entry.update || entry.deleted) !== -1;
    return this.setState({ status });
  }
  /*
  validChange(local, arrayIndex, field, value) { return true; }

  checkDuplicates(local, arrayIndex, field, value) {
    const newDuplicates = local.filter((season) => season[field] === value);
    if (newDuplicates.length === 0) {
      this.removeError(local[arrayIndex], 'duplicate|' + field);
    } else if (newDuplicates.length >= 1) {
      this.addError(local[arrayIndex], 'duplicate|' + field);
      for (const duplicate of newDuplicates) { this.addError(duplicate, 'duplicate|' + field); }
    }

    const oldDuplicates = local.filter((season, index) => season.validate && season[field] === local[arrayIndex][field] && index !== arrayIndex);
    if (oldDuplicates.length === 1) {
      this.removeError(oldDuplicates[0], 'duplicate|' + field);
    }
  }

  addError(localEntry, errorType) {
    if (!localEntry.errors) { localEntry.errors = []; }
    const errors = localEntry.errors;
    if (errors.findIndex((error) => error === errorType) !== -1) { return; }  // Don't add duplicate errors
    console.error('Add error', localEntry.id, errorType, errors);
    errors.push(errorType);
    localEntry.validate = 'error';
  }

  removeError(localEntry, errorType) {
    const errors = localEntry.errors;
    if (!errors) { return; }
    console.error('Remove error', localEntry.id, errorType, errors);
    errors.splice(errors.findIndex((error) => error === errorType), 1);
    if (errors.length === 0) { localEntry.validate = ''; }
  } */
}

PageTemplateTable.propTypes = {
  reducerState: PropTypes.shape({
    local: PropTypes.array.isRequired,
    database: PropTypes.array.isRequired,
    status: PropTypes.object.isRequired,
  }),
  actions: PropTypes.object.isRequired,
};

export default PageTemplateTable;
