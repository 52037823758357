import request from '../../utils/request';

const BASE_URL = process.env.REACT_APP_EXPRESS_URL;

class Api {
  static async getRecent(page) {
    const url = BASE_URL + '/tsg-audits?page=' + page;
    const response = await request('get', url);
    return response.data;
  }
}

export default Api;
