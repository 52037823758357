import Api from './Api';

let loading = false;
let updating = false;

function load(year, team) {
  return async function (dispatch) {
    if (loading) { return; }
    loading = true;
    let database = [];
    database = await Api.getAll(year, team);
    dispatch({ type: 'LOAD_GAMES_SUCCESS', database, year, team });
    loading = false;
    return database;
  };
}

function update(local, year, team) {
  return async function (dispatch) {
    if (updating) { return local; }
    updating = true;
    const changedEntries = local.filter((entry) =>
      entry.insert || entry.update || entry.deleted,
    );
    const database = await Api.update(year, team, changedEntries);
    dispatch({ type: 'UPDATE_GAMES_SUCCESS', database, year, team });
    updating = false;
    return database;
  };
}

const actions = { load, update };
export default actions;
