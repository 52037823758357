import React from 'react';
import PropTypes from 'prop-types'; // ES6
import { Link } from 'react-router-dom';

class NavDropdown extends React.Component {
  render() {
    return (
      <div className="dropdown">
        <button className="btn btn-info dropdown-toggle" type="button" data-toggle="dropdown">{this.props.currentTeam ? this.props.currentTeam : 'Teams'}
          <span className="caret"></span></button>
        <ul className="dropdown-menu">
          <li><Link to={'/seasons/' + this.props.currentYear + '/teams'}>Edit Teams
          </Link></li>
          {this.props.database.map((entry, arrayIndex) =>
            <li className={this.props.currentTeam === entry.team.ext_team_id ? 'active' : ''} key={entry.id}>
              <Link to={'/seasons/' + this.props.currentYear + '/teams/' + entry.team.ext_team_id + ((this.props.currentTeamPage) ? '/' + this.props.currentTeamPage : '')}>{entry.team.ext_team_id}</Link>
            </li>,
          )}
        </ul>
      </div>
    );
  }
}

NavDropdown.propTypes = {
  database: PropTypes.array,
  currentYear: PropTypes.number,
  currentTeam: PropTypes.string,
  currentTeamPage: PropTypes.string,
};

export default NavDropdown;
