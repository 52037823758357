export default function teamReducer(state = { null: { local: [], database: [], status: {} } }, action) {
  switch (action.type) {
    case 'LOAD_GAMES_SUCCESS':
      return standardReturn(state, action.year, action.team, action.database);
    case 'UPDATE_GAMES_SUCCESS':
      return standardReturn(state, action.year, action.team, action.database);
    default:
      return state;
  }
}

/* Create a local copy for editing and sort it */
function standardReturn(state, year, team, database) {
  database = database.sort(sort);
  const local = [];
  for (const entry of database) {
    if (entry.start_time_tbd) {
      entry.start_time = '';
    }
    local.push(Object.assign({}, entry));
  }
  state[year + '|' + team] = { local, database, status: {} };
  return Object.assign({}, state);
}

function sort(a, b) {
  if (a.start_timestamp < b.start_timestamp) { return -1; }
  if (a.start_timestamp > b.start_timestamp) { return 1; }
  return 0;
}
