import React from 'react';
import moment from 'moment';
import NavTop from '../../components/NavTop';
import NavRoot from '../../components/NavRoot';
import FooterButtons from '../../components/FooterButtons';
import Urls from '../../constants/Urls';

const EditTable = ({ local, onRefresh, disable, hasChanged }) => {
  return (
    <div className="container">
      <NavTop />
      <NavRoot page="recent-stats" />
      <div className="col-sm-12">
        <form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="medium">User</th>
                <th className="medium">Game</th>
                <th className="medium">Updated</th>
                <th className="medium">Opened</th>
                <th className="medium" style={{ textAlign: 'right' }}>Events</th>
              </tr>
            </thead>
            <tbody>
              {local.map((entry, index) =>
                <tr key={index}>
                  <td className="medium">
                    {entry.username}
                  </td>
                  <td className="medium">
                    <a href={Urls.stats + '/stats/game/' + entry.team_season_game.game.ext_game_id} target="_blank" rel="noreferrer">
                      {entry.team_season_game.game.ext_game_id}
                    </a>
                  </td>
                  <td className="medium">
                    {moment(entry.update_timestamp).format('lll')}
                  </td>
                  <td className="medium">
                    {moment(entry.device_open_timestamp).format('lll')}
                  </td>
                  <td className="narrow" style={{ textAlign: 'right' }}>
                    {Array.isArray(entry.events) ? entry.events.length : 0}
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
          <FooterButtons
            hasChanged={hasChanged}
            disabled={disable}
            onRefresh={onRefresh}
          />
        </form>
      </div>
    </div>
  );
};

export default EditTable;
