import React from 'react';
import { createRoot } from 'react-dom/client';
import Routes from './Routes';
import store from './utils/store';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

const Root = () => {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

createRoot(document.getElementById('root')).render(<Root />);
