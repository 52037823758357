import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './Actions';
import EditTable from './EditTable';
import PageTemplateTable from '../../components/PageTemplateTable';

class EditPage extends PageTemplateTable {
  loadData() {
    this.props.actions.load();
  }

  saveChanges(event) {
    event.preventDefault();
    this.setState({ status: { ...this.state.status, editing: true } });
    this.props.actions.update(this.state.local);
  }

  render() {
    return (
      <EditTable
        local={this.state.local}
        onChange={this.onChangeInput}
        onSave={this.saveChanges}
        onRefresh={this.onRefresh}
        hasChanged={this.state.status.hasChanged}
        disable={this.state.status.editing} />
    );
  }
}

function mapStateToProps(state) {
  return { reducerState: state.cognitoUsersState };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);
