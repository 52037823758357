// src/actions/seasonActions.js

import Api from './Api';

const loading = {};
const updating = {};

function load(year) {
  return async function (dispatch) {
    if (loading[year]) { return; }
    loading[year] = true;
    let database = [];
    database = await Api.getAll(year);
    dispatch({ type: 'LOAD_TEAM_SEASONS_SUCCESS', database, year });
    loading[year] = false;
    return database;
  };
}

function update(year, local) {
  return async function (dispatch) {
    if (updating[year]) { return local; }
    updating[year] = true;
    const changedEntries = local.filter((entry) =>
      entry.insert || entry.update || entry.deleted,
    );
    const database = await Api.update(year, changedEntries);
    dispatch({ type: 'UPDATE_TEAM_SEASONS_SUCCESS', database, year });
    updating[year] = false;
    return database;
  };
}

const actions = { load, update };
export default actions;
