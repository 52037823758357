import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './Actions';
import EditTable from './EditTable';
import PageTemplateTable from '../../components/PageTemplateTable';

let increment = 0;

class EditPage extends PageTemplateTable {
  loadData({ year }) {
    this.props.actions.load(Number(year));
    return;
  }

  addBlankEntry(array) {
    // Check if we need a new blank entry. Don't add one if user cannot edit or there is one already.
    if (array.findIndex((entry) => /^\*/.exec(entry.id) && entry.empty) !== -1) { return; }

    array.push({ id: '*' + increment, team_id: 0, division_id: 0, ulti_analytics_ext_id: 0, city: '', abbrev: '', empty: true });
    increment++;
    return;
  }

  saveChanges(event) {
    event.preventDefault();
    this.setState({ status: { ...this.state.status, editing: true } });
    this.props.actions.update(Number(this.props.year), this.state.local);
  }

  render() {
    return (
      <EditTable
        currentYear={Number(this.props.year)}
        local={this.state.local}
        onChange={this.onChangeInput}
        onSave={this.saveChanges}
        onDelete={this.onDeleteEntry}
        onRefresh={this.onRefresh}
        hasChanged={this.state.status.hasChanged}
        disable={this.state.status.editing} />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { reducerState: state.teamSeasonState[Number(ownProps.year)] };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

const Connect = connect(mapStateToProps, mapDispatchToProps)(EditPage);

export default () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  return <Connect year={pathParts[2]} />;
};
