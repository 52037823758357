import React from 'react';
import PropTypes from 'prop-types'; // ES6

class FooterButtons extends React.Component {
  render() {
    const { disable, hasChanged, onSave, onRefresh } = this.props;
    return (
      <div className="footer-buttons">
        {onSave ? <button
          type="button"
          disabled={disable || !hasChanged}
          className="btn btn-primary"
          onClick={onSave}
        >
          Save Changes
        </button> : null}
        {onRefresh ? <button
          type="button"
          disabled={disable}
          className="btn btn-primary refresh-button"
          onClick={onRefresh}
        >
          Refresh
        </button> : null}
      </div>
    );
  }
}

FooterButtons.propTypes = {
  disable: PropTypes.bool,
  hasChanged: PropTypes.bool,
};

export default FooterButtons;
