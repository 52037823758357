import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './Actions';
import playerActions from '../player/Actions';
import EditTable from './EditTable';
import PageTemplateTable from '../../components/PageTemplateTable';
import { toast } from 'react-toastify';

let increment = 0;
const MAX_NUMBER_OF_ROWS = 30;

class EditPage extends PageTemplateTable {
  constructor(props, context) {
    super(props, context);

    this.state.playerDatabase = this.props.playerState.database;
    this.onSearchKeyUp = this.onSearchKeyUp.bind(this);
    this.addRoster = this.addRoster.bind(this);
    this.createAndAddPlayer = this.createAndAddPlayer.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    this.setState({ playerDatabase: nextProps.playerState.database });
  }

  loadData({ year, team }) {
    this.props.actions.load(Number(year), team);
    this.props.playerActions.load();
    return;
  }

  addBlankEntry(array) { return; }

  saveChanges(event) {
    event.preventDefault();
    this.setState({ status: { ...this.state.status, editing: true } });
    this.props.actions.update(this.state.local, Number(this.props.year), this.props.team);
  }

  addNewRoster(playerID, firstName, lastName, jerseyNumber) {
    const local = this.state.local;
    if (local.findIndex((r) => r.player_id === playerID) !== -1) {
      toast.error('Player is already on the team.');
      return;
    }
    local.push({
      id: '*' + increment,
      player_id: playerID,
      player: {
        first_name: firstName,
        last_name: lastName,
      },
      jersey_number: jerseyNumber,
      active: true,
      insert: true,
      teams: [],
    });
    increment++;
    return this.setLocal(local);
  }

  addRoster(event, playerID, firstName, lastName) {
    event.preventDefault();
    this.addNewRoster(playerID, firstName, lastName);
  }

  async createAndAddPlayer(event) {
    event.preventDefault();
    const firstName = event.target.form[0].value;
    const lastName = event.target.form[1].value;
    const jerseyNumber = event.target.form[2].value;
    event.target.form[0].value = '';
    event.target.form[1].value = '';
    event.target.form[2].value = '';
    this.setState({ status: { ...this.state.status, editing: true } });
    const newPlayer = await this.props.actions.createPlayer(firstName, lastName);
    this.setState({ status: { ...this.state.status, editing: false } });
    this.addNewRoster(newPlayer.id, firstName, lastName, jerseyNumber);
  }

  onSearchKeyUp(event) {
    const entries = this.state.playerDatabase;
    const values = event.target.value.toLowerCase().split(' ');
    for (const entry of entries) {
      let hidden = false;
      for (const value of values) {
        let hasValue = false;
        if (entry.first_name && entry.first_name.toLowerCase().substring(0, value.length) === value) {
          hasValue = true;
        } else if (entry.last_name && entry.last_name.toLowerCase().substring(0, value.length) === value) {
          hasValue = true;
        } else if (entry.ext_player_id && entry.ext_player_id.toLowerCase().substring(0, value.length) === value) { hasValue = true; }
        if (!hasValue) { hidden = true; }
      }
      entry.hidden = hidden;
    }
    return this.setState({ playerDatabase: entries });
  }

  render() {
    return (
      <EditTable
        currentYear={Number(this.props.year)}
        currentTeam={this.props.team}
        local={this.state.local}
        playerDatabase={this.state.playerDatabase.filter((entry) => !entry.hidden).filter((entry, index) => index < MAX_NUMBER_OF_ROWS)}
        onChange={this.onChangeInput}
        onSave={this.saveChanges}
        onDelete={this.onDeleteEntry}
        onSearchKeyUp={this.onSearchKeyUp}
        onCreate={this.createAndAddPlayer}
        onAdd={this.addRoster}
        onRefresh={this.onRefresh}
        hasChanged={this.state.status.hasChanged}
        disable={this.state.status.editing} />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    reducerState: state.rosterState[ownProps.year + '|' + ownProps.team],
    playerState: state.playerState,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch), playerActions: bindActionCreators(playerActions, dispatch) };
}

const Connect = connect(mapStateToProps, mapDispatchToProps)(EditPage);

export default () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  return <Connect year={pathParts[2]} team={pathParts[4]} />;
};
