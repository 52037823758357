import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './Actions';
import EditTable from './EditTable';
import PageTemplateTable from '../../components/PageTemplateTable';

let increment = 0;

class EditPage extends PageTemplateTable {
  loadData({ year, team }) {
    this.props.actions.load(Number(year), team);
    return;
  }

  addBlankEntry(array) {
    // Check if we need a new blank entry. Don't add one if user cannot edit or there is one already.
    if (array.findIndex((entry) => /^\*/.exec(entry.id) && entry.empty) !== -1) { return; }

    array.push({ id: '*' + increment, team_season_id_home: 0, team_season_id_away: 0, score_home: 0, score_away: 0, status_id: 0, live: false, reg_season: true, lock: false, start_timestamp: '', start_timezone: '', start_time_tbd: false, aw_section: '', ext_game_id: '', empty: true });
    increment++;
    return;
  }

  saveChanges(event) {
    event.preventDefault();
    this.setState({ status: { ...this.state.status, editing: true } });
    this.props.actions.update(this.state.local, Number(this.props.year), this.props.team);
  }

  render() {
    return (
      <EditTable
        currentYear={Number(this.props.year)}
        currentTeam={this.props.team}
        local={this.state.local}
        onChange={this.onChangeInput}
        onSave={this.saveChanges}
        onDelete={this.onDeleteEntry}
        onRefresh={this.onRefresh}
        hasChanged={this.state.status.hasChanged}
        disable={this.state.status.editing} />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { reducerState: state.gameState[ownProps.year + '|' + ownProps.team] };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

const Connect = connect(mapStateToProps, mapDispatchToProps)(EditPage);

export default () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  return <Connect year={pathParts[2]} team={pathParts[4]} />;
};
