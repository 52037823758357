import request from '../../utils/request';

const BASE_URL = process.env.REACT_APP_EXPRESS_URL;

class Api {
  static async getAll(year) {
    const url = BASE_URL + '/season/' + year + '/teams';
    const response = await request('get', url);
    return response.data;
  }

  static async update(year, changedEntries) {
    const url = BASE_URL + '/season/' + year + '/teams';
    await request('post', url, { changedEntries });
    return this.getAll(year);
  }
}

export default Api;
