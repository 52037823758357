import React from 'react';
import NavTop from '../../components/NavTop';
import NavTeam from '../../components/NavTeam';
import FooterButtons from '../../components/FooterButtons';
import GameSelector from '../game/Selector';
import Urls from '../../constants/Urls';

const EditTable = ({ local, onChange, onSave, onRefresh, disable, hasChanged, currentYear, currentTeam }) => {
  return (
    <div className="container">
      <NavTop currentYear={currentYear} currentTeam={currentTeam} currentTeamPage="game-stats" />
      <NavTeam page="game-stats" currentYear={currentYear} currentTeam={currentTeam} />
      <div className="col-sm-12">
        <form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="medium">Game</th>
                <th className="medium" style={{ textAlign: 'right' }}>Events</th>
                <th className="medium" style={{ textAlign: 'right' }}>Audits</th>
              </tr>
            </thead>
            <tbody>
              {local.map((entry) =>
                <tr key={entry.id}>
                  <td className="huge">
                    <a href={Urls.stats + '/stats/game/' + entry.game.ext_game_id} target="_blank" rel="noreferrer" >
                      <GameSelector
                        id={entry.id}
                        value={Number(entry.gameId)}
                        deleted={entry.deleted}
                        disabled={true}
                        onChange={onChange}
                        currentYear={currentYear}
                        currentTeam={currentTeam}
                      />
                    </a>
                  </td>
                  <td className="narrow" style={{ textAlign: 'right' }}>
                    {Array.isArray(entry.events) ? entry.events.length : 0}
                  </td>
                  <td className="narrow" style={{ textAlign: 'right' }}>
                    {entry.audits}
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
          <FooterButtons
            hasChanged={hasChanged}
            disabled={disable}
            onSave={onSave}
            onRefresh={onRefresh}
          />
        </form>
      </div>
    </div>
  );
};

export default EditTable;
